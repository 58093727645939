import { ReactNode, useCallback, type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import Big from 'big.js'
import { useTranslation } from 'react-i18next'
import { ChargeType, Delivery } from '@/libs/helpers/adapters'
import { Bill, BillServiceCharge, ServiceChargeType } from '@/libs/helpers/apiClient'
import { OrderMethod } from '@/libs/helpers/utils'
import { Grid, Text } from '@/tbui'
import PulsateLoader from './PulsateLoader'

interface BillBreakdownProps {
  bill: Bill
  disableTip: boolean
  isCartLoading: boolean
  delivery?: Delivery
  appliedLoyaltyRewardName?: string
  appliedLoyaltyRewardAmount?: string
  orderMethod: OrderMethod
  showLoyaltyOrderDiscount?: boolean
  currency?: string
  renderAppliedDiscount?: () => JSX.Element[]
}

const StyledFeeWaived = styled.span`
  text-decoration: line-through;
  margin-right: 2px;
`

// eslint-disable-next-line complexity
const BillBreakdown: FunctionComponent<BillBreakdownProps> = ({
  bill: {
    subtotal,
    totalTax,
    tipTotal,
    grandTotal,
    isEstimated,
    isInclusive,
    giftCardPayments,
    outstandingAmount,
    serviceCharges,
  },
  disableTip,
  isCartLoading,
  delivery,
  appliedLoyaltyRewardName,
  orderMethod,
  showLoyaltyOrderDiscount = true,
  currency,
  renderAppliedDiscount,
  appliedLoyaltyRewardAmount,
}) => {
  const { t } = useTranslation()
  const isDelivery = orderMethod === OrderMethod.delivery
  const isBillPartiallyCovered = outstandingAmount !== '0.00'
  const Loader = <PulsateLoader width="50px" />

  const getGrandTotalLabel = useCallback(() => {
    if (currency) {
      return t(isInclusive ? 'bill.total_currency_inclusive_taxes' : 'bill.total_currency', {
        currency,
      })
    }

    return t(isInclusive ? 'bill.total_inclusive_taxes' : 'bill.total')
  }, [currency, isInclusive, t])

  const renderServiceChargeRow = (serviceCharge: BillServiceCharge): ReactNode => {
    return (
      <Grid template="1fr auto" key={serviceCharge.serviceChargeXRefID}>
        <Text data-test={`bill-service-charge-label-${serviceCharge.serviceChargeXRefID}`}>
          {isDelivery && delivery && serviceCharge.type === ServiceChargeType.DELIVERY_FEE
            ? `${t('bill.delivery_fee')}${
                serviceCharge.chargeType === ChargeType.PERCENTAGE ? ' (%)' : ''
              }`
            : serviceCharge.label}
        </Text>
        <Text data-test={`bill-service-charge-amount-${serviceCharge.serviceChargeXRefID}`}>
          {serviceCharge.isWaived ? (
            <>
              <StyledFeeWaived
                data-test={`bill-service-charge-waived-${serviceCharge.serviceChargeXRefID}`}
              >
                {t('currency', {
                  value: isInclusive ? serviceCharge.totalAmount : serviceCharge.chargeAmount,
                })}
              </StyledFeeWaived>
              {t('currency', {
                value: 0,
              })}
            </>
          ) : (
            // When the fee is inclusive include the fee taxes, when its not inclusive just show the fee subtotal
            t('currency', {
              value: isInclusive ? serviceCharge.totalAmount : serviceCharge.chargeAmount,
            })
          )}
        </Text>
      </Grid>
    )
  }

  return (
    <Grid gap="16px" data-test="bill-breakdown">
      {showLoyaltyOrderDiscount &&
      appliedLoyaltyRewardAmount != null &&
      Big(appliedLoyaltyRewardAmount).gt(0.0) ? (
        <Grid template="1fr auto" data-test="loyalty-discount">
          <Text data-test="selected-reward">
            {t('bill.loyalty_discount_amount', {
              name: appliedLoyaltyRewardName,
            })}
          </Text>
          {isCartLoading ? (
            Loader
          ) : (
            <Text data-test="discount-amount">
              {t('currency', { value: -appliedLoyaltyRewardAmount })}
            </Text>
          )}
        </Grid>
      ) : null}
      <Grid template="1fr auto">
        <Text>{t('bill.item_subtotal')}</Text>
        {isCartLoading ? (
          Loader
        ) : (
          <Text data-test="bill-subtotal">{t('currency', { value: subtotal })}</Text>
        )}
      </Grid>
      {renderAppliedDiscount ? renderAppliedDiscount() : null}
      {isCartLoading && isDelivery ? (
        <Grid template="1fr auto">
          <Text>{t('bill.delivery_fee')}</Text>
          {Loader}
        </Grid>
      ) : null}
      {serviceCharges.length > 0 && !isCartLoading
        ? serviceCharges
            // delivery fee is to be rendered first
            .sort((sc) => (sc.type === ServiceChargeType.DELIVERY_FEE ? -1 : 0))
            .filter((sc) => Number(sc.chargeAmount) > 0)
            .map(renderServiceChargeRow)
        : null}
      {!isInclusive ? (
        <Grid template="1fr auto">
          <Text>{t(isEstimated ? 'bill.estimated_taxes' : 'bill.taxes')}</Text>
          {isCartLoading ? (
            Loader
          ) : (
            <Text data-test="bill-total-tax">{t('currency', { value: totalTax })}</Text>
          )}
        </Grid>
      ) : null}
      {/* render tip amount when it exists for online payments only */}
      {tipTotal != null && Big(tipTotal).gt(0.0) && disableTip != null && !disableTip && (
        <Grid template="1fr auto">
          <Text>{t('bill.tip_amount')}</Text>
          {isCartLoading ? (
            Loader
          ) : (
            <Text data-test="bill-tip-amount">{t('currency', { value: tipTotal })}</Text>
          )}
        </Grid>
      )}
      <Grid
        template="1fr auto"
        m={giftCardPayments ? `10px 0 ${isBillPartiallyCovered ? '0' : '10px'}` : '10px 0'}
      >
        <Text type="H3">{getGrandTotalLabel()}</Text>
        {isCartLoading ? (
          <PulsateLoader width="60px" height="18px" />
        ) : (
          <Text type="H3" data-test="bill-grand-total">
            {t('currency', { value: grandTotal })}
          </Text>
        )}
      </Grid>
      {giftCardPayments && giftCardPayments.length > 0 && (
        <Grid
          data-test="dgc-breakdown"
          gap="15px"
          m={`0 0 ${isBillPartiallyCovered ? '20px' : '30px'} 0`}
        >
          {giftCardPayments.map((giftCard, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={`pmt-${index}`} template="1fr auto">
              <Text>{t('bill.gift_card')}</Text>
              {isCartLoading ? (
                Loader
              ) : (
                <Text data-test="dgc-amount-spent">
                  {t('currency_negative', { value: giftCard.amountSpent })}
                </Text>
              )}
            </Grid>
          ))}
          {isBillPartiallyCovered && (
            <Grid template="1fr auto">
              <Text type="H4" fontSize="14px">
                {t('bill.remaining_balance')}
              </Text>
              {isCartLoading ? (
                Loader
              ) : (
                <Text data-test="dgc-outstanding-amount" type="H4" fontSize="14px">
                  {t('currency', { value: outstandingAmount })}
                </Text>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default BillBreakdown
