import {
  ChangeEvent,
  Dispatch,
  Fragment,
  FunctionComponent,
  ReactNode,
  SetStateAction,
} from 'react'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import Big from 'big.js'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import BillBreakdown from '@/components/BillBreakdown'
import type { LoyaltySessionState } from '@/components/Checkout'
import LoyaltyContainer from '@/components/LoyaltyContainer'
import type { Delivery, OperationalMenu, VenueOrderingMethod } from '@/libs/helpers/adapters'
import {
  type Bill,
  type DiscountDetails,
  type Loyalty,
  type VenueLoyalty,
} from '@/libs/helpers/apiClient'
import {
  MapDiscountDetail,
  OrderItem,
  OrderMethod,
  formatASAPOTLimitReachedMessage,
  getOrderMethodSettings,
  getRelativeTimeToNextAvailableInterval,
} from '@/libs/helpers/utils'
import { useConfiguration } from '@/providers/configuration/ConfigurationContext'
import { useModal } from '@/providers/modal'
import { useUserLanguage } from '@/providers/userLanguage'
import { Button, CheckBox, Divider, Grid, Text } from '@/tbui'
import PopupModalTemplate from '../PopupModalTemplate'
import PromoCode from '../PromoCode'
import PulsateLoader from '../PulsateLoader'
import CartItem, { CartWidgetVariant } from './CartItem'
import EmptyCart from './EmptyCart'

interface CartBodyProps {
  cart: {
    bill: Bill
    delivery?: Delivery
    isCartLoading: boolean
    scheduledFor?: string
    appliedLoyaltyRewardName?: string
    appliedLoyaltyRewardAmount?: string
    discountedOrderItemID?: string
    items: OrderItem[]
    orderMethod: OrderMethod | undefined
    discountDetails?: DiscountDetails
  }
  variant: CartWidgetVariant
  venue: {
    isASAPAvailable: boolean
    isSchedulingAvailable: boolean
    venueReopensAt?: string | null
    disableTip?: boolean
    includeCutlery?: boolean
    name: string
    active: boolean
    orderThrottlingLimitReached: boolean
    venueOrderingMethods: VenueOrderingMethod[]
  }
  offersLoyaltyRewards: boolean
  onItemsChange: (items: OrderItem[]) => Promise<void>
  userRewards: Loyalty | null
  onClearLoyalty: () => void
  loyaltySession?: LoyaltySessionState
  onChangeCutlery?: Dispatch<SetStateAction<boolean>>
  onSelectReward?: (selectedReward: string | null) => Promise<void>
  loyaltyConfig: VenueLoyalty
  operationalMenu: OperationalMenu
  currency?: string
  hasAdvancedDiscountFF?: boolean
  promoCodeHandlers: {
    promoCodes: string[]
    addPromoCodeHandler: (promoCode: string) => Promise<void>
    removePromoCodeHandler: (promoCode: string) => Promise<void>
  }
}

const cartBodyStyles = css`
  gap: 20px;
  display: grid;
`

const cutleryStyles = css`
  display: grid;
  align-content: center;
  align-items: center;
  gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
`

const cartListStyles = css`
  display: grid;
  gap: 1rem;
`

const orderingUnavailableStyles = (theme: Theme): string => css`
  display: grid;
  align-content: start;
  align-items: start;
  padding: 24px;
  gap: 24px;
  margin-top: 50%;

  ${theme.mixin.fluidRange({
    prop: 'paddingBottom',
    fromSize: '20px',
    toSize: '40px',
  })};

  @media (max-width: ${theme.breakpoints.SM}) {
    padding: 0 24px;
  }
`

const advancedDiscountDetailStyles = css`
  color: #8c4e03;
`

const CartBody: FunctionComponent<CartBodyProps> = ({
  venue: {
    disableTip,
    active,
    venueReopensAt,
    name,
    isASAPAvailable,
    isSchedulingAvailable,
    includeCutlery,
    orderThrottlingLimitReached,
    venueOrderingMethods,
  },
  cart: {
    bill,
    isCartLoading,
    items,
    delivery,
    scheduledFor,
    appliedLoyaltyRewardName,
    appliedLoyaltyRewardAmount,
    discountedOrderItemID,
    orderMethod,
    discountDetails,
  },
  variant,
  onItemsChange,
  onChangeCutlery,
  userRewards,
  loyaltySession,
  onSelectReward,
  offersLoyaltyRewards,
  onClearLoyalty,
  loyaltyConfig,
  operationalMenu,
  currency,
  hasAdvancedDiscountFF = false,
  promoCodeHandlers: { promoCodes, addPromoCodeHandler, removePromoCodeHandler },
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { openModal, closeModal } = useModal()
  const { userLanguage } = useUserLanguage()
  const { weeklySchedule } = getOrderMethodSettings(venueOrderingMethods, OrderMethod.pickup)
  const { userSession } = useConfiguration()

  const isPickup = orderMethod === OrderMethod.pickup
  const isDelivery = orderMethod === OrderMethod.delivery
  const isMenuPage = variant === CartWidgetVariant.MENU
  const isCheckoutPage = variant === CartWidgetVariant.CHECKOUT
  const isPickupAvailable = venueOrderingMethods.some(
    (venueOrderingMethod) => venueOrderingMethod.orderMethod === OrderMethod.pickup
  )
  const isDeliveryAvailable = venueOrderingMethods.some(
    (venueOrderingMethod) => venueOrderingMethod.orderMethod === OrderMethod.delivery
  )

  if (!isSchedulingAvailable && orderThrottlingLimitReached) {
    return (
      <div className={orderingUnavailableStyles(theme)}>
        <div data-test="venue-not-active-cart">
          <Text type="H2" align="center" m="0 0 24px 0">
            {t('ordering_not_available')}
          </Text>
          <Text align="center" type="H3" bold={false}>
            {formatASAPOTLimitReachedMessage(
              t,
              isSchedulingAvailable,
              isDelivery,
              getRelativeTimeToNextAvailableInterval(
                DateTime.local(),
                15,
                weeklySchedule,
                userLanguage
              )
            )}
          </Text>
        </div>
      </div>
    )
  }

  // we only show the `Apply Loyalty Reward` button on the checkout page
  // since it's pretty standard for online ordering to only have rewards
  // applied on the checkout page
  const displayLoyaltyButton =
    !isMenuPage &&
    offersLoyaltyRewards &&
    userRewards?.rewards != null &&
    loyaltySession != null &&
    userSession.isSignedIn
  const isOrderingAvailable =
    active &&
    ((isASAPAvailable && scheduledFor == null) || (isSchedulingAvailable && scheduledFor != null))

  const renderVenueUnavailableState = (): ReactNode => {
    if (!active) {
      return (
        <div data-test="venue-not-active-cart">
          <Text type="H2" align="center" m="0 0 24px 0">
            {t('checkout.venue_inactive_header')}
          </Text>
          <Text align="center" type="H3" bold={false}>
            {t('checkout.venue_inactive_message', {
              venueName: name,
            })}
          </Text>
        </div>
      )
    }

    if (isDelivery && !isDeliveryAvailable) {
      return (
        <Text type="H2" align="center">
          {t('ordering_delivery_not_available')}
        </Text>
      )
    }

    if (isPickup && !isPickupAvailable && isDeliveryAvailable) {
      return (
        <Text type="H2" align="center">
          {t('ordering_pickup_not_available')}
        </Text>
      )
    }

    if (venueReopensAt != null) {
      return (
        <>
          <Text type="H2" align="center">
            {t('ordering_not_available')}
          </Text>
          <Text align="center" type="H3" bold={false}>
            {t('modal.closed_venue.ordering_not_available_until', {
              value: venueReopensAt,
            })}
          </Text>
        </>
      )
    }

    return (
      <>
        <Text type="H1" align="center">
          {t('restaurant_closed')}
        </Text>
        <Text type="H3" align="center" bold={false}>
          {t('closed_description')}
        </Text>
      </>
    )
  }

  const handleIncrement = async (currentItem: OrderItem, index: number): Promise<void> => {
    const { childItems, quantity, note } = currentItem
    const newQuantity = Number(quantity) + 1
    const updatedItems = items.map((cItem: OrderItem, idx: number) =>
      idx !== index
        ? cItem
        : {
            ...cItem,
            childItems,
            note,
            quantity: newQuantity.toString(),
          }
    )
    await onItemsChange(updatedItems)
  }

  const onRemoveItem = async (currentItem: OrderItem): Promise<void> => {
    const filteredItems = items.filter((cItem: OrderItem) => cItem.id !== currentItem.id)
    await onItemsChange(filteredItems)
    closeModal()
    onClearLoyalty()
  }

  const handleDecrement = async (currentItem: OrderItem, index: number): Promise<void> => {
    const { childItems, quantity, note } = currentItem
    const newQuantity = Number(quantity) - 1
    if (newQuantity > 0) {
      const updatedItems = items.map((cItem, idx: number) =>
        idx !== index
          ? cItem
          : {
              ...cItem,
              childItems,
              note,
              quantity: newQuantity.toString(),
            }
      )
      await onItemsChange(updatedItems)
    } else {
      openModal(
        <PopupModalTemplate
          name="remove-item"
          callToAction={t('modal.remove_item_confirm.submit')}
          message={t('modal.remove_item_confirm.message')}
          title={t('modal.remove_item_confirm.title')}
          onSubmit={() => onRemoveItem(currentItem)}
          onClose={closeModal}
        />,
        { variant: 'SMALL' }
      )
    }
  }

  const Loader = <PulsateLoader width="50px" />

  const renderDiscountItem = (discountDetail: MapDiscountDetail, index: number): JSX.Element => {
    return (
      <Grid key={`${discountDetail.id}-${index}`} template="1fr auto">
        <Text data-test={`discount-detail-${index}`} className={advancedDiscountDetailStyles}>
          {discountDetail.applyType === 'PROMO'
            ? t('cart_widget.item.promo', { value: discountDetail.label })
            : discountDetail.label}
        </Text>
        {isCartLoading ? (
          Loader
        ) : (
          <Text data-test={`discount-amount-${index}`} className={advancedDiscountDetailStyles}>
            {t('currency', { value: -Number(discountDetail.total).toFixed(2) })}
          </Text>
        )}
      </Grid>
    )
  }

  const renderDiscountByItem = (itemID: string, index: number): JSX.Element[] => {
    const itemOrder = discountDetails ? discountDetails.orderItemDiscounts[itemID] : undefined

    if (itemOrder && itemOrder.length > 0) {
      return itemOrder.map((order) => {
        const mappedDiscountDetails: MapDiscountDetail = {
          id: order.discountId,
          label: order.label,
          discountType: 'ITEM',
          applyType: order.applyType,
          total: order.total,
        }

        return renderDiscountItem(mappedDiscountDetails, index)
      })
    } else {
      return [<></>]
    }
  }

  // Could be integrated to BillBreakdown once we remove the FF
  const renderAppliedDiscount = (): JSX.Element[] => {
    if (discountDetails) {
      const mappedDiscountDetails = discountDetails.billTotalDiscounts.map(
        (billDiscount, index) => {
          const mappedDiscountDetails: MapDiscountDetail = {
            id: billDiscount.discountId,
            label: billDiscount.label,
            applyType: billDiscount.applyType,
            discountType: 'ORDER',
            total: billDiscount.total,
          }

          return renderDiscountItem(mappedDiscountDetails, index)
        }
      )

      return mappedDiscountDetails
    } else {
      return [<></>]
    }
  }

  const renderContent =
    items.length > 0 ? (
      <>
        <div className={cartListStyles} data-test="cart-list">
          {items.map((cartItem, index) => (
            <Fragment key={`${cartItem.id}-${cartItem.itemID}`}>
              <CartItem
                onIncrementItem={(cItem, idx) => handleIncrement(cItem, idx)}
                onDecrementItem={(cItem, idx) => handleDecrement(cItem, idx)}
                item={cartItem}
                index={index}
                variant={variant}
                isCartLoading={isCartLoading}
                operationalMenu={operationalMenu}
                disableCounters={variant === CartWidgetVariant.CHECKOUT}
              />
              {renderDiscountByItem(cartItem.id, index)}
              {cartItem.id === discountedOrderItemID &&
                appliedLoyaltyRewardAmount != null &&
                Big(appliedLoyaltyRewardAmount).gt(0.0) && (
                  <Grid
                    template="1fr auto"
                    data-test={`loyalty-discount-order-item-${cartItem.id}`}
                  >
                    <Text data-test="selected-reward">
                      {t('bill.loyalty_discount_amount', {
                        name: appliedLoyaltyRewardName,
                      })}
                    </Text>
                    {isCartLoading ? (
                      Loader
                    ) : (
                      <Text data-test="discount-amount">
                        {t('currency', { value: -appliedLoyaltyRewardAmount })}
                      </Text>
                    )}
                  </Grid>
                )}
              {variant === CartWidgetVariant.MENU && (
                <Button
                  css={{ display: 'flex', width: 'max-content' }}
                  variant="LINK"
                  onClick={() => {
                    openModal(
                      <PopupModalTemplate
                        name="remove-item"
                        callToAction={t('modal.remove_item_confirm.submit')}
                        message={t('modal.remove_item_confirm.message')}
                        title={t('modal.remove_item_confirm.title')}
                        onSubmit={() => onRemoveItem(cartItem)}
                        onClose={closeModal}
                      />,
                      { variant: 'SMALL' }
                    )
                  }}
                >
                  {t('modal.remove_item_confirm.submit')}
                </Button>
              )}
              {index < items.length - 1 && <Divider />}
            </Fragment>
          ))}
        </div>
        <Divider />
        {includeCutlery != null && onChangeCutlery != null ? (
          <>
            <div className={`cutlery-selector ${cutleryStyles}`}>
              <CheckBox
                id="include-cutlery"
                name="include-cutlery"
                isSelected={includeCutlery}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  onChangeCutlery(e.target.checked)
                }}
                variant="SQUARE"
              />
              <Text>{t('cart_widget.include_cutlery')}</Text>
            </div>
            <Divider />
          </>
        ) : null}
        {/** LTY Reward Selector */}
        {displayLoyaltyButton && !!onSelectReward && (
          <LoyaltyContainer
            userRewards={userRewards}
            loyaltySession={loyaltySession}
            onSelectReward={onSelectReward}
            loyaltyConfig={loyaltyConfig}
          />
        )}
        {hasAdvancedDiscountFF && isCheckoutPage && (
          <PromoCode
            addPromoCodeHandler={addPromoCodeHandler}
            removePromoCodeHandler={removePromoCodeHandler}
            labels={promoCodes}
          />
        )}
        <BillBreakdown
          bill={bill}
          delivery={delivery}
          disableTip={disableTip ?? false}
          appliedLoyaltyRewardName={appliedLoyaltyRewardName}
          appliedLoyaltyRewardAmount={appliedLoyaltyRewardAmount}
          showLoyaltyOrderDiscount={!discountedOrderItemID}
          isCartLoading={isCartLoading}
          orderMethod={orderMethod as OrderMethod}
          currency={currency}
          renderAppliedDiscount={hasAdvancedDiscountFF ? renderAppliedDiscount : undefined}
        />
      </>
    ) : (
      <EmptyCart variant={variant} isCartLoading={isCartLoading} />
    )

  return (
    <div className={cartBodyStyles} data-test="cart-body">
      {isOrderingAvailable ? (
        renderContent
      ) : (
        <div className={orderingUnavailableStyles(theme)}>{renderVenueUnavailableState()}</div>
      )}
    </div>
  )
}

export { CartBody }
